import React, { useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { useStyles } from "./styles";
import StyledPanel from "../StyledPanel";
import { TextField, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import Dropdown from "../../Dropdowns/SingleSelect";
import { DateTimePicker } from "@material-ui/pickers";
import ContractService from "../../../services/ContractService";
import apiClient from "../../../auth/apiClient";
import { useEnqueueSnackbar } from "../../../hooks/useEnqueueSnackbar";
import moment from "moment";
import useHasPermissions from "../../../hooks/useHasPermissions";
import clsx from "clsx";
import useCurrentFacility from "../../../hooks/useCurrentFacility";
import CredentialHistoryButton from "../../Buttons/CredentialHistory";
import { useSelector, shallowEqual } from "react-redux";
import { useAccessGroupData } from "../../../providers/AccessGroupsProvider";
import { useFeatureFlag } from "../../../hooks/useFeatureFlags";

const availableStatuses = [
  { value: "in", name: "In" },
  { value: "out", name: "Out" },
  { value: "neutral", name: "Neutral" },
];

const availableModes = [
  { value: "normal", name: "Normal" },
  { value: "locked", name: "Locked" },
  { value: "override", name: "Override" },
  { value: "override ap", name: "Override AP" },
  { value: "inactive", name: "Inactive" },
];

const contractService = new ContractService(apiClient);

const AccessHolderStatusPanel = ({ className, accessHolderID, contractID }) => {
  const classes = useStyles();
  const { facilityID } = useCurrentFacility();
  const [accessHolder, setAccessHolder] = useState({ contractID });
  const enqueueSnackbar = useEnqueueSnackbar();
  const { hasPermissions } = useHasPermissions();
  const accessHolderEdit = hasPermissions(["accessholders.edit"]);
  const theme = useTheme();
  const small = useMediaQuery(theme.breakpoints.down("sm"));
  const [isDisabled, setIsDisabled] = useState(true);
  const [prevRemainingUses, setPrevRemainingUses] = useState(0);
  const dayUse = useFeatureFlag("Day Use");
  const { accessGroupData } = useAccessGroupData();
  const [values] = accessGroupData;
  const timeZone = useSelector(
    (state) =>
      state.entities.Context?.details?.timezone
        ? state.entities.Context.details.timezone
        : moment.tz.guess(),
    shallowEqual
  );
  const regex = /^[0-9]{1,6}$/;
  const defaultValue = 0;
  
  useEffect(() => {
    if (accessHolderID) fetchPanelInfo(accessHolderID);
  }, [accessHolderID]);

  const fetchPanelInfo = useCallback(
    async (accessHolderID) => {
      let tmpAccessHolder;
      try {
        let response = await contractService.getAccessHolder(
          facilityID,
          accessHolderID
        );
        tmpAccessHolder = response.data;

      } catch {
        enqueueSnackbar("Failed to retrieve access holder status info", {
          variant: "error",
          tag: "accessHolderStatusInfoError",
        });
      }
      
      setPrevRemainingUses(tmpAccessHolder.remainingUses ?? 0);
      setAccessHolder(tmpAccessHolder);
      setIsDisabled(false);
    },
    [accessHolderID]
  );

  const handleStatusSelect = async (status) => {
    if (!accessHolder) return;
    let previousStatus = accessHolder.status;
    let tmpAccessHolder = accessHolder;
    tmpAccessHolder.status = status;
    setAccessHolder((prev) => ({ ...prev, tmpAccessHolder }));
    if (!(await updateAccessHolder(tmpAccessHolder)))
      setAccessHolder((prev) => ({ ...prev, status: previousStatus }));
  };

  const handleModeSelect = async (mode) => {
    if (!accessHolder) return;
    let previousMode = accessHolder.mode;
    let tmpAccessHolder = accessHolder;
    tmpAccessHolder.mode = mode;
    setAccessHolder((prev) => ({ ...prev, tmpAccessHolder }));
    if (!(await updateAccessHolder(tmpAccessHolder)))
      setAccessHolder((prev) => ({ ...prev, mode: previousMode }));
  };

  const isValidRemainingUses = (previousRemainingUses) => {
    let tmpAccessHolder = accessHolder;
    if (isNaN(previousRemainingUses)) {
      tmpAccessHolder.remainingUses = defaultValue;
      enqueueSnackbar("Remaining Uses must be number", {
        variant: "error",
        tag: "updatenumberError",
      });
    } else if (!regex.test(previousRemainingUses) && parseInt(previousRemainingUses || defaultValue) !== defaultValue) {
      tmpAccessHolder.remainingUses = defaultValue;
      enqueueSnackbar(Number(previousRemainingUses) === Math.floor(Number(previousRemainingUses)) ?
        "Remaining Uses range must be 0 to 999999" : "Remaining Uses must be round number", {
        variant: "error",
        tag: "updaterangeError",
      });
    } else {
      tmpAccessHolder.remainingUses = parseInt(previousRemainingUses);
    }
    setAccessHolder((prev) => ({ ...prev, tmpAccessHolder }));
  };

  const handleRemainingUses = async (changedRemainingUses) => {
    accessHolder.metaData = `{ "PrevRemainingUses": ${prevRemainingUses ?? 0} }`;
    if (changedRemainingUses != prevRemainingUses) {
      if (!(await updateAccessHolder(accessHolder)))
        setAccessHolder((prev) => ({ ...prev, remainingUses: changedRemainingUses }));
    }
  };

  const updateAccessHolder = async (accessHolder) => {
    try {
      var result = await contractService.updateAccessHolder(accessHolder);
      if (result.status == 200) {
        setAccessHolder(result?.data);
        setPrevRemainingUses(result?.data.remainingUses ?? 0);
      }
      return true;
    } catch {
      enqueueSnackbar("Failed to update access holder", {
        variant: "error",
        tag: "updateAccessHolderError",
      });
      return false;
    }
  };

  const updateAccessHolderDates = async (accessHolder) => {
    let startDate = moment.isMoment(accessHolder.startDate)
      ? accessHolder.startDate
      : moment.utc(accessHolder.startDate).tz(timeZone);
    let endDate = moment.isMoment(accessHolder.endDate)
      ? accessHolder.endDate
      : null;
    accessHolder.startDate = startDate;
    accessHolder.endDate = endDate;

    try {
      await contractService.updateAccessHolder(accessHolder);
      return true;
    } catch (ex) {
      console.log(ex);
      enqueueSnackbar("Failed to update access holder", {
        variant: "error",
        tag: "updateContractError",
      });
      return false;
    }
  };

  const isEndDateAfterStateDate = (start, end) => {
    if (!start) return false;
    if (!start && end) return false;
    if (!end && start) return true;

    let startDate = moment.isMoment(start)
      ? start
      : moment.utc(start).tz(timeZone);
    let endDate = moment.isMoment(end)
      ? end
      : moment.utc(end).tz(timeZone);

    return endDate.isAfter(startDate);
  };

  const handleStartDateChange = async (startDate) => {
    if (!accessHolder) return;
    if (!isEndDateAfterStateDate(startDate, accessHolder.endDate)) {
      enqueueSnackbar("End date must be after start date", {
        variant: "error",
        tag: "startDateError",
      });
      return;
    }
    let prevStartDate = accessHolder.startDate;
    let tmpAccessHolder = accessHolder;
    tmpAccessHolder.startDate = startDate;
    setAccessHolder((prev) => ({ ...prev, ...tmpAccessHolder }));
    if (!(await updateAccessHolderDates(tmpAccessHolder)))
      setAccessHolder((prev) => ({
        ...prev,
        startDate: prevStartDate,
      }));
  };

  const handleEndDateChange = async (endDate) => {
    if (!accessHolder) return;
    if (!isEndDateAfterStateDate(accessHolder.startDate, endDate)) {
      enqueueSnackbar("End date must be after start date", {
        variant: "error",
        tag: "endDateError",
      });
      return;
    }
    let prevEndDate = accessHolder.endDate;
    let tmpAccessHolder = accessHolder;
    tmpAccessHolder.endDate = endDate;
    setAccessHolder((prev) => ({ ...prev, ...tmpAccessHolder }));
    if (!(await updateAccessHolderDates(tmpAccessHolder)))
      setAccessHolder((prev) => ({
        ...prev,
        endDate: prevEndDate,
      }));
  };

  return (
    <StyledPanel
      className={clsx("accessholder-status-panel", className)}
      headerContent={
        <div className={clsx("header", classes.header)}>
          <Typography
            className={clsx("access-holder-status", classes.statusHeader)}
          >
            {!small ? "Access Holder Status" : "Status"}
          </Typography>
          {accessHolder && (
            <CredentialHistoryButton
              accessHolderID={accessHolder.accessHolderID}
              className={classes.statusHistoryBtn}
              small={small}
            />
          )}
        </div>
      }
      cardContent={
        <div className={classes.statusContainer}>
          <Dropdown
            className={clsx(
              "status-dropdown",
              "flex-input",
              classes.flexDropdown
            )}
            name="Status"
            id="Status"
            aria-labelledby="Status"
            options={availableStatuses}
            onSelect={handleStatusSelect}
            value={accessHolder?.status?.toLowerCase() ?? ""}
            disabled={!accessHolderEdit || isDisabled}
            InputProps={{
              readOnly: Boolean(!accessHolderEdit),
              "aria-readonly": Boolean(!accessHolderEdit),
              disabled: Boolean(!accessHolderEdit),
            }}
          />
          <Dropdown
            className={clsx(
              "mode-dropdown",
              "flex-input",
              classes.flexDropdown
            )}
            name="Mode"
            options={availableModes}
            onSelect={handleModeSelect}
            value={accessHolder?.mode?.toLowerCase() ?? ""}
            disabled={!accessHolderEdit || isDisabled}
            InputProps={{
              readOnly: Boolean(!accessHolderEdit),
              "aria-readonly": Boolean(!accessHolderEdit),
              disabled: Boolean(!accessHolderEdit),
            }}
          />
          {(dayUse && values?.isDayUseAvailable) && (
            <TextField
              className={clsx("Remaining-Uses", classes.flexInput)}
              label="Remaining Uses"
              onBlur={e => handleRemainingUses(e.target.value)}
              onChange={e => isValidRemainingUses(e.target.value)}
              type="text"
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              name="RemainingUses"
              id="RemainingUses"
              value={accessHolder.remainingUses ? accessHolder.remainingUses : (isDisabled ? "" : defaultValue)}
              disabled={!accessHolderEdit || isDisabled}
            />
          )}
          <DateTimePicker
            className={clsx("contract-start", "flex-input")}
            label="Start Date"
            value={
              accessHolder?.startDate
                ? moment.utc(accessHolder.startDate).tz(timeZone)
                : null
            }
            format="MM/DD/YY hh:mm a"
            id="Start Date"
            onChange={handleStartDateChange}
            disabled={!accessHolderEdit || isDisabled}
            InputProps={{
              readOnly: Boolean(!accessHolderEdit),
              "aria-readonly": Boolean(!accessHolderEdit),
              disabled: Boolean(!accessHolderEdit),
            }}
          />
          <DateTimePicker
            className={clsx("contract-end", "flex-input")}
            label="End Date"
            value={
              accessHolder?.endDate
                ? moment.utc(accessHolder.endDate).tz(timeZone)
                : null
            }
            initialFocusedDate={
              accessHolder?.endDate
                ? moment.utc(accessHolder.endDate).tz(timeZone)
                : moment.utc(Date.now()).tz(timeZone)
            }
            clearable={true}
            id="End Date"
            format="MM/DD/YY hh:mm a"
            onChange={handleEndDateChange}
            disabled={!accessHolderEdit || isDisabled}
            data-testId="endDate"
            inputProps={{
              "aria-label": "End Date",
            }}
            InputProps={{
              readOnly: Boolean(!accessHolderEdit),
              "aria-readonly": Boolean(!accessHolderEdit),
              disabled: Boolean(!accessHolderEdit),
            }}
          />
        </div>
      }
    />
  );
};

AccessHolderStatusPanel.propTypes = {
  className: PropTypes.string,
  accessHolderID: PropTypes.string,
};

export default AccessHolderStatusPanel;
